import { routes } from "@prmf/site-router";
import { Link, useFetcher } from "@remix-run/react";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import BgImage from "./BgImage";

const Contact: React.FC = () => {
  return (
    <>
      <h2 className="font-bold">Get in touch</h2>
      <address className="space-y-1 not-italic">
        <h4 className="mt-6 font-bold">Head office & showroom</h4>
        <span className="block">Blenheim Road</span>
        <span className="block">Lancing Business Park</span>
        <span className="block">Lancing</span>
        <span className="block">West Sussex</span>
        <span className="block">BN15 8UH</span>
      </address>
      <Link to={routes.contact} className="button-accent mt-0">
        Contact Us
      </Link>
    </>
  );
};
const Newsletter: React.FC = () => {
  const ref = useRef<HTMLFormElement>(null);
  const { data, Form, state } = useFetcher<{ ok?: true; message: string }>();
  const isLoading = state === "submitting";

  useEffect(() => {
    if (data?.ok) {
      ref.current?.reset();
    } else if (data?.message) {
      toast.error(data.message, { id: "newsletter" });
    }
  }, [data]);

  return (
    <>
      <h2 className="font-bold">Subscribe to our Newsletter</h2>
      <Form
        ref={ref}
        preventScrollReset
        className="mx-auto max-w-xs space-y-3"
        method="post"
        action="/newsletter"
      >
        {!isLoading && data?.ok && (
          <h3 className="font-bold text-emerald-500 empty:hidden">
            Thank you for subscribing!
          </h3>
        )}
        <input
          name="email"
          type="email"
          placeholder="Enter email"
          required
          className="w-full rounded border-gray-200 py-3 text-center"
        />
        <select
          className="w-full rounded border-gray-200 py-3 text-center"
          name="customerType"
          required
          defaultValue={""}
        >
          <option value="" disabled>
            Customer type: please select
          </option>
          <option>House Builder</option>
          <option>Social Housing Provider</option>
          <option>High Rise Developer</option>
        </select>
        <button
          type="submit"
          className="button-accent disabled:opacity-30"
          disabled={isLoading}
        >
          {isLoading ? "Submitting…" : "Submit"}
        </button>
        <p>
          <small>
            You can unsubscribe at any time.
            <br />
            For more information, please read our{" "}
            <Link to={routes.terms} className="underline">
              Privacy Policy.
            </Link>
          </small>
        </p>
      </Form>
    </>
  );
};
const Brochure: React.FC = () => {
  return (
    <>
      <BgImage
        src="/media/assets/home-brochures-cta.png"
        className={clsx(
          "!absolute inset-0 bg-transparent",
          "!bg-contain bg-right-bottom",
          "max-sm:opacity-50",
          "lg:!bg-[length:140%] lg:!bg-right-bottom",
          "2xl:!bg-contain",
        )}
      />
      <h2 className="relative font-bold">Our Brochures</h2>
      <Link
        to={routes.brochures}
        className="button-accent self-align-bottom relative mt-auto"
      >
        View and Download
      </Link>
    </>
  );
};

export default function ContactCTA() {
  return (
    <div className="mdl:grid-cols-2 container mt-20 grid gap-10 lg:grid-cols-3">
      {[Contact, Newsletter, Brochure].map((C, idx) => (
        <section
          key={idx}
          className={clsx(
            "relative flex flex-col gap-4 rounded-lg last:rounded-b-none lg:rounded-b-none",
            "bg-zinc-100 p-8 pb-14 text-center text-sm font-light",
            "max-md:last:rounded-b-none md:last:col-span-2 lg:last:col-span-1",
          )}
          children={<C />}
        />
      ))}
    </div>
  );
}
